import sports from '@cached/sport.json';
import percorsi from '@cached/percorso.json';
const _percorsi = {
    percorsi: percorsi,

    sportHasPercorso: function (sportId) {
        const sport = _percorsi.findSport(sportId);
        if (!sport) return false;
        return (sport.has_percorso);
    },

    andaturaHasPercorso: function (andatura) {
        return (andatura.andatura_percorso);
    },

    findSport: function (id) {
        return sports.find(el=>el.id == id);
    },

    findPercorso: function (codice) {
        return percorsi.find(el=>el.codice === codice);
    },

    nomePercorso: function (codice) {
        let percorso = _percorsi.findPercorso(codice);
        if (!percorso) return "";
        return percorso.nome;
    },

    iconaPercorso: function (codice) {
        let percorso = _percorsi.findPercorso(codice);
        if (!percorso) return "";
        return percorso.icon;
    },
}
export default _percorsi;

export const methods = {
    nomePercorso: _percorsi.nomePercorso,
    andaturaHasPercorso: _percorsi.andaturaHasPercorso,
    sportHasPercorso: _percorsi.sportHasPercorso,
    iconaPercorso: _percorsi.iconaPercorso,
    findPercorso: _percorsi.findPercorso,
}
